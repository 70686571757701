<template>
  <span>
    <span v-if="!subscribing">
      <b-button class="mr-2" v-if="subscribed" variant="primary" title="unsubscribe" @click="unsubscribe">subscribed</b-button>
      <b-button class="mr-2" v-if="!subscribed" variant="outline-primary" title="subscribe" @click="subscribe">subscribe</b-button>
    </span>
    <span v-if="subscribing">
      <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
    </span>
  </span>
</template>

<script>

export default {
  name: 'Subscribing',
  props: ['id', 'content'],
  computed: {
    message: {
      get () {
        return this.$store.state.message
      },
      set (payload) {
        this.$store.commit('setMessage', payload)
      }
    },
    notifications: {
      get () {
        return this.$store.state.notifications
      },
      set (payload) {
        this.$store.commit('setNotifications', payload)
      }
    },
    subscribed: function () {
      let id
      if (this.content === 'publishers') {
        id = this.id
      } else {
        id = parseInt(this.id)
      }
      const ids = []
      this.notifications.forEach(n => {
        if (n.config[this.ref[this.content].filter]) {
          n.config[this.ref[this.content].collection].forEach(l => {
            ids.push(l)
          })
        }
      })
      return ids.find(x => x === id)
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.load()
  },
  data () {
    return {
      ref: {
        labels: {
          collection: 'labels',
          filter: 'filterByLabels'
        },
        tags: {
          collection: 'tags',
          filter: 'filterByTags'
        },
        topics: {
          collection: 'topics',
          filter: 'filterByTopics'
        }
      },
      subscribing: false
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        this.notifications = await this.$Amplify.API.get('cosmos', `/notifications/byemail/${this.user.username}`)
        if (this.$route.query.subscribe && !this.subscribed) {
          await this.subscribe()
          this.$router.push(this.$route.path)
        }
        if (this.$route.query.subscribe && this.subscribed) {
          console.log('You are already subscribed!')
          this.message = {
            title: 'Subscription update',
            body: 'You are already subscribed'
          }
          this.$router.push(this.$route.path)
        }
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    subscribe: async function () {
      try {
        this.subscribing = true
        this.notifications = await this.$Amplify.API.get('cosmos', `/notifications/subscribe/${this.user.username}/${this.content}/${this.id}`)
        this.notifications = await this.$Amplify.API.get('cosmos', `/notifications/byemail/${this.user.username}`)
        this.subscribing = false
        console.log(this.subscribed)
        this.message = {
          title: 'Subscription update',
          body: 'You have been subscribed'
        }
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    unsubscribe: async function () {
      try {
        this.subscribing = true
        await this.$Amplify.API.get('cosmos', `/notifications/unsubscribe/${this.user.username}/${this.content}/${this.id}`)
        this.notifications = await this.$Amplify.API.get('cosmos', `/notifications/byemail/${this.user.username}`)
        this.subscribing = false
        console.log(this.subscribed)
        this.message = {
          title: 'Subscription update',
          body: 'You have been unsubscribed'
        }
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  }
}
</script>
