<template>
  <div v-if="show">
    <p class="mb-0"><strong>
      <router-link :to="{ name: 'NewsItem', params: { id: publication.id}}" >{{publication.name}}</router-link></strong>
      <b-button variant="outline-primary" size="sm" class="ml-2" v-on:click="unlink">unlink from event</b-button>
    </p>
    <p class="mb-0"><small>{{publication.source}} - {{publication.date}}</small></p>
    <p class="mb-0">{{publication.body}}</p>
    <p class="mb-2"><small><a :href="publication.link" target="_blank">external link</a></small></p>
  </div>
</template>

<script>
import ac from '@/libs/accesscontrol'

export default {
  name: 'EventPublication',
  props: ['publication', 'event'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permissions.unlink = ac.can(this.user.acgroups).deleteAny('event').granted
  },
  data () {
    return {
      show: true,
      permissions: {
        unlink: false
      }
    }
  },
  methods: {
    unlink: function () {
      try {
        this.$Amplify.API.del('cosmos', `/event/unlink/${this.event.id}/${this.publication.id}`)
        this.show = false
      } catch (e) {
        this.$logger.error('saved ERROR: ' + e)
      }
    }
  }
}
</script>

<style>
</style>
