<template>
  <div class="card-body pb-0" v-if="!deleted">
    <h4 class="card-title"><router-link class="titleLink" :to="{ name: 'Event', params: { id: event.id }}">{{event.name}} [{{event.id}}]</router-link></h4>
    <div class="mb-0">
      {{event.date}}
      <span v-if="permissions.delete">
        <b-button variant="outline-primary" size="sm" class="ml-2" v-on:click="deleteEvent">delete</b-button>
      </span>
      <span v-if="permissions.edit">
        <b-button variant="outline-primary" size="sm" class="ml-2" :to="{ name: 'EventEdit', params: { id: event.id }}">edit</b-button>
      </span>
    </div>
    <div class="mb-0"><markdown :content="event.comments" /></div>
    <div class="mb-0" v-if="$config.BUILD === 'full'">
      <small class="text-info">created by: {{event.createdBy}}</small>
    </div>

    <!-- Related news -->
    <span v-if="showRelatedNews">
      <div class="text-center m-0 mb-3 p-0">
        <b-button variant="outline-primary" size="sm" v-if="!showPublicationFlag" v-on:click="showPublications">more</b-button>
      </div>
      <span v-if="showPublicationFlag">
        <div class="my-3">
          <small>:: RELATED NEWS ::</small>
          <span v-if="loadingPublications"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
          <span v-if="publications.length == 0"><small>no related news</small></span>
        </div>
        <div v-for="publication in publications" :key="publication.id">
          <event-publication :publication="publication" :event="event"></event-publication>
        </div>
        <div class="my-3">
          <small>:: RELATED REGTOPICS ::</small>
          <span v-if="loadingTopics"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
          <span v-if="topics.length == 0"><small>no related regtopics</small></span>
        </div>
        <div v-for="topic in topics" :key="topic.id">
          <p class="mb-0"><router-link :to="{ name: 'Topic', params: { id: topic.id }}">{{topic.name}}</router-link></p>
        </div>
      </span>
    </span>

  </div>
</template>

<script>

import Markdown from '@/components/Markdown.vue'
import EventPublication from '@/components/EventPublication.vue'

export default {
  name: 'Event',
  props: ['event', 'username', 'showRelatedNews', 'permissions', 'publicationId'],
  beforeDestroy () {
  },
  components: {
    EventPublication,
    Markdown
  },
  computed: {
    ownEvent: function () {
      return this.event.createdBy === this.username
    }
  },
  created: function () {
  },
  data () {
    return {
      deleted: false,
      loadingPublications: false,
      loadingTopics: false,
      publications: [],
      showPublicationFlag: false,
      topics: []
    }
  },
  methods: {
    deleteEvent: function () {
      if (!this.publicationId) {
        this.deleteEventDelete()
      } else {
        this.deleteEventUnlink()
      }
    },
    deleteEventDelete: function () {
      this.$logger.debug('delete started')
      try {
        let apiName = 'cosmos'
        let path = `/event/${this.event.id}`
        this.$Amplify.API.del(apiName, path)
        this.deleted = true
        this.$emit('eventDeleted')
      } catch (e) {
        this.$logger.error('saved ERROR: ' + e)
      }
    },
    deleteEventUnlink: function () {
      this.$logger.debug('unlink started')
      try {
        let apiName = 'cosmos'
        let path = `/event/${this.event.id}/publication/${this.publicationId}`
        this.$Amplify.API.del(apiName, path)
        this.deleted = true
        this.$emit('eventDeleted')
      } catch (e) {
        this.$logger.error('saved ERROR: ' + e)
      }
    },
    showPublications: async function () {
      this.showPublicationFlag = true
      this.loadingPublications = true
      this.loadingTopics = true
      try {
        let apiName = 'cosmos'
        let path = `/news/event/${this.event.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.publications = response
        this.loadingPublications = false
      } catch (e) {
        this.$logger.error('saved ERROR: ' + e)
      }
      try {
        let apiName = 'cosmos'
        let path = `/topics/event/${this.event.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.topics = response
        this.loadingTopics = false
      } catch (e) {
        this.$logger.error('saved ERROR: ' + e)
      }
    }
  },
  mounted () {
  },
  watch: {
  }
}
</script>

<style>
</style>
