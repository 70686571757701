<template>
  <div>
    <div class="mb-2"><strong class="mr-2">{{nameProp}}</strong>
      <b-button class="mr-2" v-if="editableProp" variant="outline-primary" size="sm" v-on:click="edit = true">edit</b-button>
    </div>
    <div class="mb-2">
      <span v-if="!loading">
        <div v-for="value in values" :key="value.id">
          <span v-if="linkableProp">
            <router-link :to="{ name: linkNameProp, params: { id: value.id }}">
              {{value.name}}
            </router-link>
          </span>
          <span v-if="!linkableProp">
            {{value.name}}
          </span>
          <b-button v-if="edit" variant="outline-primary" size="sm" v-on:click="deleteEdge(value)">remove</b-button>
        </div>
      </span>
      <span v-if="loading">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </span>
    </div>
    <div v-if="edit">
      <span v-if="!busy">
        <b-form-select class="mb-2" v-model="selected" :options="options" />
        <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="saveEdge">save</b-button>
        <b-button class="mr-2" variant="outline-primary" size="sm" v-on:click="edit = false">done</b-button>
      </span>
      <span v-if="busy"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'EdgeSelectList',
  props: {
    considerDelProp: {
      type: Boolean,
      default: true
    },
    directionProp: String,
    edgeLabelProp: String,
    editableProp: {
      type: Boolean,
      default: true
    },
    linkableProp: {
      type: Boolean,
      default: false
    },
    linkNameProp: {
      type: String,
      default: ''
    },
    listSourceProp: Object,
    nameProp: String,
    vertexProp: Object
  },
  /** props
  * vertexProp object { id: id, partition: partition}
  * directionProp string 'from vertex' or 'from list'
  * edgeLabelProp string - name of the edge
  * listSourceProp object { partition: partition, label: label}
  * */
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.selected
    }
  },
  created: function () {
    this.loadEdges()
  },
  data () {
    return {
      busy: false,
      edit: false,
      loading: true,
      options: [],
      selected: '',
      values: [],
      vertices: []
    }
  },
  methods: {
    buildOptions: async function () {
      this.$logger.debug('buildOptions start')
      this.busy = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/label/${this.listSourceProp.partition}/${this.listSourceProp.label}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('got vertices', response)
        this.vertices = _.sortBy(response, ['name'])
        let optionsTemp = []
        optionsTemp[0] = { value: null, text: 'select' }
        for (let i = 0, len = this.vertices.length; i < len; i++) {
          optionsTemp[i + 1] = { value: this.vertices[i].id, text: this.vertices[i].name }
        }
        if (this.values.length > 0) {
          for (let i = 0, len = this.values.length; i < len; i++) {
            _.remove(optionsTemp, _.matchesProperty('value', this.values[i].id))
          }
        }
        this.options = _.sortBy(optionsTemp, ['name'])
        this.selected = null
        this.busy = false
      } catch (e) {
        this.$logger.warn('getting vertices error error: ' + e)
      }
    },
    deleteEdge: async function (value) {
      this.$logger.debug('deletePropertyList started')
      this.busy = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/edge`
        let outvertex; let invertex = {}
        if (this.directionProp === 'from vertex') {
          outvertex = this.vertexProp
          invertex = value
        } else {
          outvertex = value
          invertex = this.vertexProp
        }
        let params = {
          body: {
            invertex: invertex,
            outvertex: outvertex,
            edge: this.edgeLabelProp
          }
        }
        let response = await this.$Amplify.API.del(apiName, path, params)
        this.$logger.debug('deleted OK:', response)
        let temp = this.values
        _.pull(temp, value)
        this.values = temp
        this.buildOptions()
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.busy = false
    },
    loadEdges: async function () {
      this.$logger.debug('loadEdges started')
      this.busy = true
      try {
        let edgeDirection = ''
        if (this.directionProp === 'from vertex') {
          edgeDirection = 'out'
        } else {
          edgeDirection = 'in'
        }
        let apiName = 'cosmos'
        let path = ''
        if (this.considerDelProp) {
          path = `/vertex/${this.vertexProp.id}/${this.vertexProp.partition}/edge/${edgeDirection}/${this.edgeLabelProp}/vertices`
        } else {
          path = `/vertex/${this.vertexProp.id}/${this.vertexProp.partition}/edge/${edgeDirection}/${this.edgeLabelProp}/vertices/no-del`
        }
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('loadEdges OK', response)
        this.values = _.sortBy(response, ['name'])
        this.loading = false
      } catch (e) {
        this.$logger.warn('/topic/:id error', e)
      }
      this.buildOptions()
    },
    saveEdge: async function () {
      this.$logger.debug(`saveEdge start`)
      this.busy = true
      const optionVertex = _.find(this.vertices, ['id', this.selected])
      let source = {}
      let target = {}
      if (this.directionProp === 'from vertex') {
        source = this.vertexProp
        target = optionVertex
      } else {
        source = optionVertex
        target = this.vertexProp
      }
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/edge`
        let params = {
          body: {
            source: source,
            target: target,
            edge: this.edgeLabelProp,
            username: this.user.username
          }
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.values.push(optionVertex)
        this.selected = null
        this.buildOptions()
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.busy = false
    }
  }
}
</script>

<style>
</style>
